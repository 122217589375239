<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-uppercase display-2 font-weight-black"
        >
          Course Phone
          <v-col>
            <v-btn color="primary" class="text-uppercase" @click="addnew">
              Add Phone
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.phone"
                      type="number"
                      :rules="nameRules"
                      :counter="10"
                      label="Phone Number"
                      required
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" v-show="editedIndex > -1" text @click="delItem"
              >Delete</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="save()"
              :disabled="!valid"
            >
              Save
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <BaseLoading :loading="loading" />
      <div class="text-capitalize display-1 my-5">Phone</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        hide-details
      ></v-text-field>
      <v-data-table
        dark
        fixed-header
        height="500"
        :headers="headers"
        :items="phoneLists"
        :items-per-page="pageSize"
        :page.sync="page"
        @page-count="pageCount = $event"
        :search="search"
        :sort-desc="['id']"
        hide-default-footer
        class="elevation-1 my-5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </v-container>
  </div>
</template>

<script>
import CourseServices from '@/services/CourseServices'
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data() {
    return {
      headers: [
        { text: 'Phone_Number', value: 'phone', align: 'start' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
      nameRules: [
        v => !!v || 'Phone is required',
        v => v.length <= 10 || 'Phone Number must be less than 10 characters',
        v => v.length > 9 || 'Phone Number must be more than 10 characters',
      ],
      phoneLists: [],
      editedIndex: -1,
      editedItem: {
        phone: '',
      },
      defaultItem: {
        phone: '',
      },
      dialog: false,
      modal: 'new',
      activeItem: 'New Phone',
      loading: false,
    }
  },
  created() {
    this.readData()
  },
  methods: {
    readData() {
      CourseServices.getCourseLists()
        .then(res => {
          if (res.status === 200) {
            this.phoneLists = []
            this.phoneLists = res.data
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    addnew() {
      this.activeItem = 'New Phone'
      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = this.phoneLists.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.activeItem = 'Edit Phone'
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.edit()
      } else {
        this.create()
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = !this.loading

          CourseServices.createPhone({
            phone: this.editedItem.phone,
          })
            .then(res => {
              console.log(res.data)
              this.$swal.fire({
                icon: 'success',
                title: 'Phone created successfully',
              })
              this.loading = !this.loading
              this.phoneLists.push(res.data)
              this.close()
            })
            .catch(err => {
              this.$swal.fire({
                icon: 'error',
                title: `${err.response.data.message}`,
              })
              this.loading = !this.loading
              if (err.response.status === 401) {
                this.removeToken()
                this.$router.push({ name: 'Admin' })
              }
            })
        } catch (err) {
          console.log(err)
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          this.loading = !this.loading
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        }
      }
    },
    edit() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading

            CourseServices.updatePhone(this.editedItem.id, {
              phone: this.editedItem.phone,
            })
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Phone edited successfully',
                })
                this.loading = !this.loading
                Object.assign(
                  this.phoneLists[this.editedIndex],
                  this.editedItem,
                )
                this.close()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    delItem() {
      this.$swal
        .fire({
          title: 'Delete ?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading

            CourseServices.deletePhone(this.editedItem.id)
              .then(() => {
                this.$swal.fire({
                  icon: 'Delete Success',
                  title: 'Phone Delete successfully',
                })
                this.loading = !this.loading
                this.phoneLists.splice(this.editedIndex, 1)
                this.close()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
  },
}
</script>

<style></style>
