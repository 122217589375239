import { apiServices } from '@/utils/request'

export default {
  getCourseLists() {
    return apiServices.get('/course-lists')
  },
  createPhone(formData) {
    return apiServices.post('/course-lists', formData)
  },

  updatePhone(id, formData) {
    return apiServices.put(`/course-lists/${id}`, formData)
  },
  deletePhone(id) {
    return apiServices.delete(`/course-lists/${id}`)
  },
}
