<template>
  <div>
    <CourseTable />
  </div>
</template>

<script>
import CourseTable from '@/components/Admin/Course/PhoneTable'
export default {
  name: 'Course',
  components: { CourseTable },
}
</script>

<style scoped></style>
